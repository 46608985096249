

/*--------------------------------------------------------------
3. content
--------------------------------------------------------------*/

/*3.1 wpo-hero-slider*/

.wpo-hero-section-1,
.wpo-hero-section-2,
.wpo-hero-section-3,
.wpo-hero-section-4,
.wpo-hero-section-5,
.wpo-hero-section-6,
.wpo-hero-section-7{
	position: relative;
	height: 900px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	z-index: 1;

	@media(max-width:1400px){
		height: 700px;
		border-bottom: 1px solid #ebebeb;
	}

	@media(max-width:991px){
		height: 500px;
		background: #0877c71c;
		border-bottom: 0;
	}
	@media(max-width:767px){
		height:450px;
	}


	&:before{
		position: absolute;
		right: -10%;
		top: -40%;
		width: 986px;
		height: 986px;
		content: "";
		background: #0877c74f;
		border-radius: 50%;
		z-index: -1;

		@media(max-width:1500px){
			width: 786px;
			height: 786px;
		}
		@media(max-width:991px){
			display: none;

		}
	}
	
	

	.right-vec{
		z-index: 1;
  
		@media(max-width:1440px){
		  top: -30px;
		}
		@media(max-width:1200px){
		  top: 0px;
		}
		@media(max-width:991px){
		  display: none;
		}
		.right-items-wrap{
			z-index: -11;
			background: none;
			display: flex;

			@media(max-width:991px){
               display: block;
			   position: relative;
			   height: unset;
			}

			.r-img{
				flex-basis: 50%;
				img{
					width: 100%;
					@media(max-width:991px){
						display: none;
					 }
				}
			}

			.right-item{
				margin: 0 20px;
				@media(max-width:1400px){
					margin: 0 10px;
				}
				.wpo-happy-client{
					padding: 45px 30px;
					display: flex;
					align-items: center;
					z-index: 99;
					display: flex;
					justify-content: center;

					@media(max-width:1300px){
						padding: 30px 10px;
						display: flex;
						justify-content: center;
						
					}
	
					h3{
						font-size: 52px;
						font-family: $heading-font;
						margin-bottom: 0px;
					}
					p{
						margin-bottom: 0;
						font-size: 22px;
						color: #838383;
						font-family: $heading-font;
					}
		
					.wpo-happy-client-slide{
						max-width: 275px;
						list-style: none;

						@media(max-width:1500px){
							max-width: 205px;
							margin-left: 0;
						}
	
						.owl-nav{
							display: none;
						}
		
						li{
							width: 55px;
							height: 55px;
	
							img{
								width: 55px;
								height: 55px;
								@include rounded-border(50%);
							}
						}
					}
				}

				&:first-child{
					.r-img{
						img{
							border-radius: 200px 200px 40px 40px;
						}
					}
				}
				&:last-child{
					.r-img{
						img{
							border-radius: 40px 40px 180px 180px;
						}
					}
				}

				.wpo-total-project{
                    padding:20px 30px;
					@include linear-gradient-bg( to left,darken($theme-primary-color-s2, 32%), transparentize($theme-primary-color-s2, 0.15));
					border-radius: 40px;
					overflow: hidden;
					position: relative;
					z-index: 1;
					margin-bottom: 30px;

					@media(max-width:1400px){
						padding:12px 10px;
					}

					.project-shape{
						position: absolute;
						right: -140px;
						top: -60px;
						z-index: -1;
					}

					.wpo-total-project-wrap{
						display: flex;
						align-items: center;

						.wpo-total-project-icon{
                           width: 90px;
						   height: 90px;
						   line-height: 90px;
						   background: darken($theme-primary-color-s2, 25%);
						   border-radius: 50%;
						   text-align: center;
						   margin-right: 30px;

						   @media(max-width:1400px){
							width: 70px;
							height: 70px;
							line-height: 70px;
							margin-right: 17px;
						   }

						   .fi{
							   font-size: 40px;
							   color: $white;
							   @media(max-width:1400px){
								font-size: 20px;
							}
						   }
						}

						.wpo-total-project-text{
							h3{
								font-size: 45px;
								color: $white;
								@media(max-width:1400px){
									font-size: 30px;
									margin-bottom: 0;
								}
							}

							p{
								color: $white;
								margin-bottom: 0;
							}
						}
					}
				}
			}
	
			  
		}

	}

  
	.wpo-hero-title h2{
	  font-size: 60px;
	  font-weight: 400;
	  line-height: 85px;
	  margin:10px 0 25px;

	  @media(max-width:1600px){
		font-size: 50px;
		line-height: 75px;
	  }
	  @media(max-width:1200px){
		font-size: 40px;
    	line-height: 60px;
	  }
	  @media(max-width:575px){
		font-size: 25px;
	  }
	}
	.wpo-hero-title h2 span{
	  color: $theme-primary-color;
	}

	.wpo-hero-title-top{
		span{
			font-size: 22px;
			color: $theme-primary-color;
			font-weight: 600;
		}
	}

	.wpo-hero-subtitle{
		p{
			font-size: 25px;
			color: $text-light-color;
			line-height: 35px;
			max-width: 595px;
			margin-bottom: 40px;
			font-weight: 400;

			@media(max-width:1200px){
				font-size: 18px;
			}
			@media(max-width:991px){
				margin-bottom: 30px;
			}
		}
	}
  }



.wpo-hero-section-text{
	position: relative;
	padding-left: 160px;

	@media(max-width:1600px){
		padding-left: 80px;
	}
	@media(max-width:1400px){
		padding-left: 30px;
	}
	@media(max-width:991px){
		padding-left:10px;
	}
}