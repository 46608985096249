.decoration:after, .decoration:before {
    position: absolute;
    content: "";
    display: block;
    width: 12px;
    height: 1px;
    background-color: grey;
    top: 50%;
}

.decoration:after {
    right: 16px;
}

.decoration:before {
    left: 16px;
}


.decoration {
    position: relative;
    display: inline-block;
    font-size: 14px;
    color: black;
    /* margin: var(--space-2) 0; */
    line-height: 1;
}

ol.progtrckr li.progtrckr-done{
    border-bottom: 4px solid #2876B3;
}

ol.progtrckr li.progtrckr-done:before{
    background-color:#2876B3;
}
.MuiCheckbox-colorSecondary.Mui-checked{
    color:#2876B3 !important;
}

ol.progtrckr li.progtrckr-doing:hover:before{
    color:#2876B3 !important;

}
ol.progtrckr li.progtrckr-todo:hover:before{
    color:#2876B3 !important;

}

.customButton {
    background-color: #217ec2;
    color: white;
}

.customButton:hover {
    color: #fff;
    background-color: #0b5ed7;
    border-color: #0a58ca;
}