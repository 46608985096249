$white: #fff;
$gray_light: #dedede;
$gray: #bbb;
$black: #fff;
$general: #a2a2a2;
$black_soft: #333;
$black_col: #2e333c;
$black_more: #0f0f0f;
$color: #ff343f;
$default_background: #212428;

.de-menu-profile {
    position: relative;
    cursor: pointer;
    img {
      width: 35px;
      border-radius: 30px;
      -moz-border-radius: 30px;
      -webkit-border-radius: 30px;
      margin-left: 5px;
      margin-bottom: 2px;
    }
    .popshow {
      cursor: default;
      width: 250px;
      position: absolute;
      background: white;
      border: solid 1px rgba(255, 255, 255, 0.1);
      border-radius: 5px;
      overflow: hidden;
      inset: 50px auto auto -200px;
      padding: 20px;
      animation: smoothDrop 0.2s ease;
      -webkit-animation: smoothDrop 0.2s ease;
      box-shadow: 2px 2px 30px 0px rgba(20, 20, 20, 0.1);
      z-index: 1;
      .d-name {
        margin-bottom: 15px;
        h4 {
          font-weight: bold;
          display: block;
          margin: 0;
          margin-top: 0px;
          padding: 0;
          font-size: 16px;
          margin-top: 5px;
        }
        span.name {
          color: $color;
          cursor: pointer;
        }
      }
      .d-balance {
        font-size: 14px;
        margin-bottom: 15px;
        color: #a2a2a2;
        h4 {
          font-weight: bold;
          display: block;
          margin: 0;
          margin-top: 0px;
          padding: 0;
          font-size: 16px;
          margin-top: 5px;
        }
      }
      .d-wallet {
        font-size: 14px;
        margin-bottom: 15px;
        color: #a2a2a2;
        h4 {
          font-weight: bold;
          display: block;
          margin: 0;
          margin-top: 0px;
          padding: 0;
          font-size: 16px;
          margin-top: 5px;
        }
        .d-wallet-address {
          display: inline-block;
          max-width: 64%;
          overflow: hidden;
        }
        #btn_copy {
          margin-left: 15px;
        }
      }
      .d-line {
        margin: 15px 0 15px 0;
        border-top: solid 1px #bbbbbb;
      }
      .de-submenu-profile {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          padding: 5px 0;
          span {
            cursor: pointer;
            color: #232f4b !important;
            background: none;
            padding: 0;
            font-weight: bold;
            font-size: 14px;
            display: block;
            text-align: left;
            &:hover {
              box-shadow: none;
            }
            i {
              padding: 7px 9px;
              background: rgba(255, 255, 255, 0.1);
              border-radius: 30px;
              width: 28px;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }


  @media (max-width: 440px) {
    .wpo-site-header .navbar-header .navbar-brand img {
      max-width: 150px !important;
    }
  }